<template>
	<div id="container"></div>
</template>
<script type="text/javascript">
window._AMapSecurityConfig = {
	securityJsCode: "6e618efcf94ae3c62f878a5693af0b28",
};
</script>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import axios from "axios";

export default {
	name: "Mapview",
	data() {
		return {
			map: null,
		};
	},
	created() {},
	mounted() {
		this.initMap();
	},
	methods: {
		initMap() {
			AMapLoader.load({
				key: "44ded493d93980a78e3291f9244dae15", // 申请好的Web端开发者Key，首次调用 load 时必填
				version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				plugins: ["AMap.Geolocation", "AMap.Geocoder"], // 需要使用的的插件列表
			})
            .then((AMap) => {
                let _this = this;
                AMap.plugin("AMap.Geolocation", function () {
                    var geolocation = new AMap.Geolocation({
                        enableHighAccuracy: true, //是否使用高精度定位，默认:true
                        timeout: 10000, //超过10秒后停止定位，默认：5s
                        position: "RB", //定位按钮的停靠位置
                        offset: [10, 20], //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
                        zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
                        extensions: "base",
                    });
                    geolocation.getCurrentPosition(function (status, result) {
                        console.log(result);
                        if (status === "complete") {
                            if(result.formattedAddress != "undefined" && result.formattedAddress != ""  && result.formattedAddress != undefined ){
                                _this.$emit('address', result.formattedAddress)
                                return;
                            }
                            // 当浏览器定位 没有获取到详细信息时，使用 经纬度获取 详细地址
                            var lnglat = result.position.lng + "," + result.position.lat;
                            var key = "fe14a27e8c50d4e66446360b6ad75879";
                            var url =
                                "https://restapi.amap.com/v3/geocode/regeo?key=" +
                                key +
                                "&location=" +
                                lnglat +
                                "&poitype=&radius=&extensions=base&batch=false&roadlevel=0";
                                axios.get(url).then(res => {
                                    _this.$emit('address', res.data.regeocode.formatted_address)
                                })
                        } else {
                            _this.$emit('address', "")
                            //alert("根据经纬度查询地址失败");
                        }
                    });

                });

            })
            .catch((e) => {
                console.log(e);
            });
		},
	},
};
</script>

